<template>
    <div class="about page-fade-in">
        <div class="banner">
        
        </div>
        <div class="about-text">
            <div class="inner-wrap">
                <h1 class="title">关于我们</h1>
                <p class="text" style="margin-bottom: 24px;">
                    厦门利成智能科技有限公司是金利集团控股企业，为政府招商引进企业。公司以智慧城市综合服务商为战略定位，主营业务涵盖：建筑智能化及智能安防工程的规划、设计与施工，物联网技术，智慧政务信息化平台及智慧社区信息化管理平台的研发、生产与销售。
                </p>
                <p class="text" style="margin-bottom: 24px;">
                    公司拥有信息系统集成与服务、电子与智能化工程专业承包等多项行业资质，获得：全国智能建筑行业优秀企业称号，中国智慧城市建设推荐品牌，安防百强企业以及高新技术企业。
                </p>
                <p class="text">
                    公司大力发展物联网云停车、智慧社区、智慧酒店、智慧交通、智慧医疗、智慧建筑等综合服务，具备强大的技术团队，拥有自主规划、研发设计和运作能力，在各个领域都有大量的经典成功案例，深受广大客户的推崇与信赖。
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "About",
        methods: {}
    }
</script>

<style lang="scss" scoped>
    .about {
        width: 100%;
        
        .banner {
            background: url('../assets/image/about_banner.png');
            background-size: cover;
            height: 700px;
            width: inherit;
        }
        
        .about-text {
            width: inherit;
            background: #FFFFFF;
            padding-bottom: 100px;
            
            .inner-wrap {
                width: 1200px;
                margin: 0 auto;
                padding-top: 100px;
                
                .title {
                    margin-top: 0;
                    margin-bottom: 32px;
                    font-size: 3rem;
                    text-align: center;
                }
                
                .text {
                    margin-top: 0;
                    margin-bottom: 0;
                    text-align: justify;
                    line-height: 24px;
                    font-size: 16px;
                    color: #666666;
                }
            }
        }
    }
    
    @media only screen and (max-width: 767px) {
        .about {
            height: auto;
            
            .banner {
                height: 350px;
                background: url("../assets/image/pic_aboutus.png") center;
                background-size: cover;
            }
            
            .about-text {
                padding-bottom: 0;
                
                .inner-wrap {
                    width: auto;
                    box-sizing: border-box;
                    padding: 24px 16px;
                    
                    .title {
                        font-size: 18px;
                        margin: 0 0 16px;
                    }
                    
                    .text {
                        font-size: 14px;
                        line-height: 21px;
                    }
                }
            }
        }
    }
</style>
